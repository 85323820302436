import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Slider from "react-slick"

import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"

import foto2 from "../images/foto2.jpg"
import foto5 from "../images/foto5.jpg"
import foto4 from "../images/foto4.jpg"

const Locations = () => {
  const [height, setHeight] = useState(100)

  const boundBox = useRef(null)

  useEffect(() => {
    setHeight(boundBox.current.clientHeight)
  })

  const sliderSettings = {
    speed: 0,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
    centerMode: false,
    focusOnSelect: true,
    adaptiveHeight: true,
    dots: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  }

  const landscapesImg = [
    {
      source: `${foto2}`,
    },
    {
      source: `${foto5}`,
    },
    {
      source: `${foto4}`,
    },
  ]
  return (
    <Layout>
      <Navbar title="Locations" />

      <div
        className="hidden lg:grid absolute bg-red red-cut"
        style={{ height }}
      ></div>
      
      <div ref={boundBox}>
        <section
          className="w-screen bg-white py-18"
          style={{ overflow: "hidden" }}
        >
          <div className="container mx-auto">
            <div className="mx-auto max-w-2xl pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24 items-center">
              <div className="col-span-3 mb-10">
                <p className="lg:max-w-7xl text-locations max-w-4xl leading-normal tracking-widest text-black text-center py-4 px-4 lg:pr-12 ">
                  FOR OVER 30 YEARS OUR TEAM AT AG FILMS HAVE BEEN PRODUCING AND
                  SERVICING FILM, PHOTOGRAPHIC, AND EXPERIENTIAL CONTENT
                  THROUGHOUT PORTUGAL AND IT'S TERRITORIES.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className="landscape-block hidden">
            {landscapesImg.map((landscape, i) => (
              <img
                className={`md:block landscape-img`}
                style={{ width: "auto" }}
                src={landscape.source}
                alt="Workflow"
              />
            ))}
          </div>
                
        <div className="landscape-block-mb block lg:hidden">
          <Slider {...sliderSettings} style={{marginBottom: 60}}>
            {landscapesImg.map((landscape, i) => (
              <div style={{
                width: '100%',
              }}>
                <img
                  className={`landscape-img-mobile`}
                  style={{float: 'right'}}
                  src={landscape.source}
                  alt="Workflow"
                />
              </div>
            ))}
          </Slider>
        </div>
        </section>

        <section className="w-screen overflow-x-hidden">
          <div className="container mx-auto">
            <div className="mx-auto max-w-3xl object-center pb-16 lg:grid lg:max-w-3xl lg:grid-cols-1 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 mt-14">
                <p className="number-contact-1 leading-normal tracking-widest text-black text-center px-4">
                  Along the way we've been lucky enough to discover, utilise,
                  and catalogue a vast array of unique cinematic spaces and
                  landscapes.
                </p>
                <br />
                <p className="number-contact-1 leading-normal tracking-widest text-black text-center py-2 px-4">
                  Our locations database is one of the most up-to-date,
                  comprehensive libraries in the country - full of hidden gems
                  not to be found anywhere else.
                </p>
                <br />
                <p className="number-contact-1 leading-normal tracking-widest text-black text-center py-2 px-4">
                  We provide full location services to fulfil all of your
                  production needs. Contact us today and we'll put together the
                  perfect package for your production.
                </p>
                <br />
                {/* <p className="number-contact-1 leading-normal tracking-widest text-black text-center py-2 px-4">
                  Click here to take a look through our website and database.
                </p> */}
                <button style={{ width: '100%' }} className="button-ads-1 my-8 inline py-2 px-5 object-center text-md font-medium leading-5 text-black text-center transition-colors duration-150 focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700">
                <Link className="uppercase" to="mailto:james@agfilms.tv">email us for further information or enquires</Link>
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="25.81"
                    viewBox="0 0 730 26"
                    style={{ position: "relative", margin: 'auto' }}
                  >
                    <path
                      d="M730,.14c-1.42,9.76-9.82,17.26-19.98,17.26H0"
                      style={{
                        fill: "none",
                        stroke: "#000",
                        strokeMiterlimit: 10,
                        strokeWidth: 1,
                      }}
                    />
                  </svg>
              </button>
   
            </div>
          </div>
        </section>
      </div>
      <div
        className="absolute bg-red red-cut-mb"
      ></div>
      <ContactForm light />
      <Footer />
    </Layout>
  )
}

export default Locations
